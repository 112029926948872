<template>
    <div v-show="props.compType === 'attachmentUpload'">
        <el-row>
            <el-col :span="24">
                <div class="form-item-column">
                    <div class="title">标题</div>
                    <el-input class="input" v-model="props.label"></el-input>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="15">
            <el-col :span="12">
                <div class="form-item-column">
                    <div class="title">栅格</div>
                    <el-input-number v-model="props.span" :min="1" :max="24" />
                </div>
            </el-col>
            <el-col :span="12">
                <div class="form-item-column">
                    <div class="title">标签宽度</div>
                    <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="15">
            <el-col :span="12">
                <div class="form-item-column">
                    <div class="title">大小(MB)</div>
                    <el-input-number v-model="props.fileSize" :min="1" :max="500" :step="10" />
                </div>
            </el-col>
            <el-col :span="12">
                <div class="form-item-column">
                    <div class="title">附件数量上限</div>
                    <el-input-number v-model="props.few" :min="1" :max="20"></el-input-number>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="form-item-row">
                    <span class="title">必填</span>
                    <el-switch v-model="props.required"></el-switch>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {
        changeId
    } from '../mixin'

    export default {
        name: "attachmentConfig",
        props: ['props'],
        components: {},
        mixins: [changeId],
        data() {
            return {}
        },
        methods: {
        },
        mounted() {},
        watch: {}
    }

</script>

<style lang="scss" scoped>
    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }

    .el-row + .el-row {
        margin-top: 25px;
    }

    .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }

    .form-item-column {
        .title {
            margin-bottom: 12px;
        }
    }

    .form-item-row {
        .title {
            margin-right: 15px;
        }
    }
</style>
