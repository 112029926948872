<template>
    <div v-show="props.compType === 'address'">
        <el-form-item label="标题">
            <el-input class="input" v-model="props.label"></el-input>
        </el-form-item>
        <!-- <el-form-item label="格式">
            <el-radio-group v-model="props.Format">
                <el-radio :label="1">省</el-radio>
                <el-radio :label="2">省市区（县）</el-radio>
                <el-radio :label="3">省市区-街道</el-radio>
            </el-radio-group>
        </el-form-item> -->
        <el-form-item label="提示符">
            <el-input class="input" v-model="props.placeholder" placeholder="请输入提示符" />
        </el-form-item>
        <el-form-item label="栅格">
            <el-input-number v-model="props.span"  :min="1" :max="24"/>
        </el-form-item>
        <!-- <el-form-item label="栅格间隔">
            <el-input-number v-model="props.gutter" :min="0"></el-input-number>
        </el-form-item> -->
        <el-form-item label="标签宽度">
            <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
        </el-form-item>
        <el-form-item label="必填">
            <el-switch v-model="props.required"></el-switch>
        </el-form-item>
    </div>
</template>
<script>
    import {
        isNumberStr
    } from '../utils/index'
    export default {
        name: "inputConfig",
        props: ['props', 'getFormId'],
        data() {
            return {
                tempOptions: []
            }
        },
        methods: {
            handlerChangeLabel(val) {
                this.props.labelWidth = val ? '80' : '1'
            },
            setValue(val) {
                if (Array.isArray(val)) {
                    return val.join(',')
                }
                if (['string', 'number'].indexOf(val) > -1) {
                    return val
                }
                if (typeof val === 'boolean') {
                    return `${val}`
                }
                return val
            },
            setOptionValue(item, val) {
                item.value = isNumberStr(val) ? +val : val
            },
            addSelectItem() {
                this.props.options.push({
                    label: '',
                    value: ''
                })
            },
        },
        mounted() {}
    }

</script>
<style lang="scss" scoped>
    .input {
        width: 75%
    }
    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }

</style>
