<template>
    <div v-show="props.compType === 'approvalFormSelect'">
        <el-form-item label="提示符">
            <el-input class="input" v-model="props.placeholder" placeholder="请输入提示符" />
        </el-form-item>
        <el-form-item label="指定关联审批单">
            <el-select v-model="props.associatedApprovalForm" multiple>
                <el-option
                    v-for="(it, idx) in optionsData"
                    :key="idx"
                    :label="it.label"
                    :value="it.value"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="栅格">
            <el-input-number v-model="props.span"  :min="1" :max="24"/>
        </el-form-item>
        <el-form-item label="标签宽度">
            <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
        </el-form-item>
        <el-form-item label="必填">
            <el-switch v-model="props.required"></el-switch>
        </el-form-item>

        <!-- <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
            <el-tree 
            :data="data" 
            :props="defaultProps" 
            :check-strictly="true"
            @node-click="handleNodeClick" 
            @check-change="handleCheckChange" 
            show-checkbox></el-tree>
        </dialog-wrapper> -->
    </div>
</template>
<script>
    import {
        DialogWrapper
    } from 'common-local';
    import {
        CampusOAModel
    } from "@/models/CampusOA";
    import {
        mapState
    } from 'vuex';
    export default {
        name: "inputConfig",
        components: {
            DialogWrapper
        },
        props: ['props', 'getFormId'],
        data() {
            return {
                tempOptions: [],
                schoolIdObj: {
                    schoolId: '',
                    formName: '',
                    groupId: '',
                    status: '1',
                    releaseStatus: '2',
                    userPopulation: ''
                },
                // 弹出框配置
                dialogObj: {
                    title: '指定关联审批单',
                    dialogVisible: false,
                    width: '400px'
                },
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                optionsData: [],
                designationData: [],
            }
        },
        computed: {
            ...mapState({
                schoolId: state => state.schoolId,
                groupId: state => state.approvalManagementGroupId
            })
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.initData();
                this.initAjaxData()
            },
            initData() {
                this.schoolIdObj.schoolId = this.schoolId;
                this.schoolIdObj.groupId = this.groupId;
            },
            initAjaxData() {
                this.getFocusInput()
            },
            getFocusInput() {
                const campusOA = new CampusOAModel()
                campusOA.getHandoverFormList(this.schoolIdObj).then((res) => {
                    if (res.data.code == '200') {
                        res.data.data.forEach((item) => {
                            let obj = {
                                label: item.formName,
                                value: item.id
                            }

                            this.optionsData.push(obj)
                        })
                        
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            focusInput() {
                this.dialogObj.dialogVisible = true;
            },
            handleNodeClick(data) {
                console.log(data);
            },
            handleCheckChange (data) {
                var bol = false,
                    idx = 0;
                this.designationData.forEach((item, index) => {
                    if (item == data) {
                        bol = true;
                        idx = index;
                    } 
                });

                if (bol) {
                    this.designationData.splice(idx, 1)
                } else {
                    this.designationData.push(data);
                }
             
                this.props.designation = this.designationData;
            },
            handleClose() {
                this.dialogObj.dialogVisible = false;
            },
        }
    }

</script>
<style lang="scss" scoped>

    .el-tree /deep/ .el-tree-node .is-leaf + .el-checkbox .el-checkbox__inner{display: inline-block;}

    .el-tree /deep/ .el-tree-node .el-checkbox .el-checkbox__inner{display: none;}

    .input {
        width: 75%
    }

    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }

</style>
