<template>
    <div v-show="props.compType === 'applicantStu'">
        <el-form-item label="标题">
            <el-input class="input" v-model="props.label"></el-input>
        </el-form-item>
        <el-form-item label="占位提示">
            <el-input class="input" v-model="props.placeholder" placeholder="请输入提示符" />
        </el-form-item>
        <el-form-item label="选项">
            <el-radio-group v-model="props.options">
                <el-radio label="1">只能选择一个人</el-radio>
                <el-radio label="2">可同时选择多个人</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="可选范围">
            <el-radio-group v-model="props.selectableRange">
                <!-- <el-radio label="1">员工</el-radio> -->
                <el-radio label="2">家长</el-radio>
                <!-- <el-radio label="3">员工及家长</el-radio> -->
            </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="是否为申请人">
            <el-radio-group v-model="props.applicant">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
            </el-radio-group>
        </el-form-item> -->
        <el-form-item label="必填">
            <el-switch v-model="props.required" disabled></el-switch>
        </el-form-item>
        <el-form-item label="栅格">
            <el-input-number v-model="props.span" :min="1" :max="24" />
        </el-form-item>
        <!-- <el-form-item label="栅格间隔">
            <el-input-number v-model="props.gutter" :min="0"></el-input-number>
        </el-form-item> -->
        <el-form-item label="标签宽度">
            <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
        </el-form-item>

    </div>
</template>
<script>
    import {
        changeId
    } from '../mixin'
    /**
     * input的配置项
     */
    export default {
        name: "inputConfig",
        props: ['props', 'getFormId'],
        mixins: [changeId],
        data() {
            return {
                val: '',
            }
        },
        methods: {
            handlerChangeLabel(val) {
                this.props.labelWidth = val ? '80' : '1'
            },
        },
        mounted() {
        },
        watch: {}
    }

</script>
<style lang="scss" scoped>
    .input {
        width: 75%;
        height: 33px !important;
    }

    .rule-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .close-btn {
        text-align: center;
    }

    .close-icon:hover {
        cursor: pointer;
    }

    .close-btn>>>.el-icon-remove-outline {
        color: "red"
    }

    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }
    
    ::v-deep .el-form-item .el-radio {
        line-height: 32px;
    }
    
    .el-radio-group {
        display: block;
    }

</style>
